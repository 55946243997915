import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Stack, ThemeProvider, Button, MenuItem, Tooltip, Alert, Snackbar, ListItemIcon, ListItemText, Divider, Menu, Typography, Box, Grid, IconButton, Paper, } from '@mui/material';
import { ApprovalRounded, ArrowBack, AutoAwesome, MedicalServicesOutlined, MoreHorizOutlined, PictureAsPdfOutlined, TextSnippetOutlined, UploadFileOutlined, RemoveRedEyeOutlined, FaxOutlined, SendOutlined, ArchiveOutlined, CheckBoxRounded, FileDownloadOutlined, EditNoteSharp, DataObjectOutlined, } from '@mui/icons-material';
import awsExports from '../aws-exports';
import { theme } from '../Theme';
import { MAX_EMAIL_FILESIZE_LIMIT, isDemandReadyForSubmission, getApprovalCTALabel, doesCarrierExist, userHasPermission, isRequestApprovalEnabled, isUploadMedicalsEnabled, } from '../common';
import { DOCUMENT_UPLOADED, AWAITING_MEDICAL_EXTRACTION } from '../common-demand';
import { DISABLED_EDIT_DOCUMENTS_TOOLTIP_TEXT } from '../common-document';
import { buildProviderSummary } from '../common-medicals';
import MedicalsProviderSummary from './MedicalsProviderSummary';
import MedicalsTreatmentsChart from '../eCharts/MedicalsTreatmentsChart';
import MedicalsOverview from './MedicalsOverview';
import MedicalsOverviewPrecedent from './MedicalsOverviewPrecedent';
import MedicalExtractionSendDialog from '../dialogs/MedicalExtractionSendDialog';
import MedicalsTreatments from './MedicalsTreatments';
import AttorneySendDialog from '../dialogs/AttorneySendDialog';
import CarrierSendDialog from '../dialogs/CarrierSendDialog';
import CarrierSendFaxDialog from '../dialogs/CarrierSendFaxDialog';
import SendManuallyDialog from '../dialogs/SendManuallyDialog';
import ArchiveDialog from '../dialogs/ArchiveDialog';
import SendPreviewDialog from '../dialogs/SendPreviewDialog';
import StatusDialog from '../dialogs/StatusDialog';
import EditDemand from '../DemandComponents/DemandForms/EditDemand';
import { archiveApi, sendManuallyApi, searchCarriersApi, getDocumentBatches, getConfigValue } from '../api';
import { fetchTemplateData, fetchTemplateSpecificVersionData } from '../redux/thunks/templateData';
import CollapseCard from '../CommonComponents/CollapseCard';
import EditMenu from '../DemandComponents/EditMenu';
import { useDispatch, useSelector } from 'react-redux';
import { setEditAllDocumentsOpen, setDemandDetailsEditOpen, setMedicalExtractionDialogOpen, setAiPromptBuilderOpen, } from '../redux/slices/demandDomSlice';
import { fetchCustomerData } from '../redux/thunks/customerData';
import { setBatches, clearDocumentState, setUsesDividerPages} from '../redux/slices/documentSlice';
import { clearMedicalsState } from '../redux/slices/medicalsSlice';
import { setReimportMedicals, setUploadMedicals, setEditDemandNotes, setEditDocumentNames, setSendForAIProcessingConfirmation } from '../redux/slices/globalDialogueSlice';
import { isCompositeDemand, hasNotAllowedStatus, canComposeDemand } from '../DemandComponents/helpers';
import StatusChip from '../CommonComponents/Chips/StatusChip';
import UploadDocumentsMenuItem from '../DemandComponents/UploadDocumentsMenuItem';
import DemandWorkflowTooltip from '../DemandComponents/DemandWorkflowTooltip';
import { useGetAllFilesByDocumentIdQuery } from "../services/documentFiles/documentFilesApi";
import { useGetDocumentDataQuery, useLazyGetDocumentDataQuery } from '../services/documentData/documentDataApi';
import AnimatedDialog from '../dialogs/AnimatedDialogWrapper';
import SaveAnimationDialog from '../dialogs/SaveAnimationDialog';
import AiPromptBuilder from '../DemandComponents/DemandForms/AiPromptBuilder';
import CustomMuiDialogWrapper from '../CommonComponents/CustomMuiDialogWrapper';
import { Transition } from '../Transition';

export const MedicalsAdminViewer = () => {
  const { documentId } = useParams();

  const rootApiUrl = awsExports.ROOT_API_URL;
  const [documentFileSize, setDocumentFileSize] = useState();
  const [providerSummary, setProviderSummary] = useState([]);
  const [formData, setFormData] = useState({});
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
  const [statusDialogText, setStatusDialogText] = useState('');
  const [statusDialogTitle, setStatusDialogTitle] = useState('');
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const [toastSeverity, setToastSeverity] = useState('success');
  const [toastTimeout, setToastTimeout] = useState(2000);
  const [anchorEl, setAnchorEl] = useState();
  const [faxDialogOpen, setFaxDialogOpen] = useState(false);
  const [attorneyDialogOpen, setAttorneyDialogOpen] = useState(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [manualDialogOpen, setManualDialogOpen] = useState(false);
  const [carrierDialogOpen, setCarrierDialogOpen] = useState(false);
  const [carriersData, setCarriersData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [uploadErrorState, setUploadErrorState] = useState({});
  const [isDocumentUploading, setIsDocumentUploading] = useState(false);
  const [useDemandNotesEnabled, setUseDemandNotesEnabled] = useState(false);
  const { usesDividerPages } = useSelector((state) => state.Document);

  const demandEditOpen = useSelector((state) => state.DemandDom.demandEditOpen);
  const { medicalExtractionDialogOpen } = useSelector((state) => state.DemandDom);
  const documentDataState = useSelector((state) => state.Document);
  const { medicals } = useSelector((state) => state.Medicals);

  const {
    isAiProcessingForCreateDocument,
    documentData,
    documentMetaData,
    documentStatus,
    deliveryAttorneyEmail,
    deliveryCarrierFax,
    deliveryCarrierEmail,
  } = documentDataState;
  const { customerId } = documentData || {};

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user, userData } = useSelector((state) => state.User);

  //RTK Query
  // fetch document data with RTK query hook but still reading from document slice
  useGetDocumentDataQuery({documentId, pollPdfStatus: false});
  const [triggerGetDocumentData] = useLazyGetDocumentDataQuery();

  const { demandS3RegenerationSkeletonData, demandS3RegenerationLoadingData } = useSelector((state) => state.PdfCustomSlice);
  const isPdfRegenLoading = Boolean(demandS3RegenerationLoadingData[documentId]) || (Boolean(demandS3RegenerationSkeletonData[documentId]));

  const { data: documentFiles = [], isLoading: documentFilesLoading } = useGetAllFilesByDocumentIdQuery(documentId, {
    refetchOnMountOrArgChange: true,
  });

  const setConfigs = async () => setUseDemandNotesEnabled((await getConfigValue('useDemandNotesEnabled', null, user)) === 1);
  const { demandDetailsEditOpen, aiPromptBuilderOpen } = useSelector((state) => state.DemandDom);

  useEffect(() => {
    if (user && documentId) {
      setConfigs();
      searchCarriers(user, '');
    }
  }, [user, documentId]);

  useEffect(() => {
    if (user && documentMetaData) {
      dispatch(fetchTemplateData({ templateId: documentMetaData?.demandTemplateId, user }));
      dispatch(fetchTemplateSpecificVersionData({ templateId: documentMetaData?.demandTemplateId, version: documentMetaData?.demandTemplatePinnedVersion, user }));
    }
  }, [user, documentMetaData]);

  useEffect(() => {
    if (customerId && user) {
      dispatch(fetchCustomerData({ customerId, user }));
    }
  }, [customerId, user]);

  const getUseDividerPagesEnabled = async (customerId) => {
    const useDividerPagesEnabled = await getConfigValue("useDividerPagesEnabled", customerId, user) || false;
    dispatch(setUsesDividerPages(useDividerPagesEnabled));
  };

  useEffect(() => {
    getUseDividerPagesEnabled(documentData?.customerId) // Get the customer from the document than from the user
  }, [])

  const searchCarriers = async (user, searchText) => {
    const response = await searchCarriersApi(user, searchText);
    response.json().then((data) => setCarriersData(data));
  };

  const sendManually = async (sendDate, sendReason, sendMethod) => {
    setManualDialogOpen(false);
    const manualFormData = {
      sendDate: sendDate.format('YYYY-MM-DDTHH:mm:ssZ'),
      sendReason: sendReason,
      sendMethod: sendMethod,
    };

    sendManuallyApi(documentId, manualFormData, user).then((response) => {
      if (response.status == 200) {
        showStatusDialog('Success! The demand has been updated.');
        triggerGetDocumentData({documentId, pollPdfStatus: true});
      } else {
        showStatusDialog('There was an error updating the demand.');
      }
    });
  };

  const archiveDemand = async (archiveReason, archiveComments) => {
    setArchiveDialogOpen(false);
    const archiveData = {
      archiveReason: archiveReason,
      archiveComments: archiveComments,
    };

    archiveApi(documentId, archiveData, user).then((response) => {
      if (response.status == 200) {
        showStatusDialog('Success! The demand has been archived.');
        triggerGetDocumentData({documentId, pollPdfStatus: false});
      } else {
        showStatusDialog('There was an error archiving the demand.');
      }
    });
  };

  const handlePreviewEmail = () => {
    handleMenuClose();
    setPreviewDialogOpen(true);
  };

  const handleSendComplete = (success) => {
    success && triggerGetDocumentData({documentId, pollPdfStatus: true});
  };

  const mapServerDataToFormElements = () => {
    if (documentData == null) return;

    const tempFormData = { ...formData };
    // tempFormData['attorney_first_name'] = documentData.sendingFirm?.attorney?.firstName;
    // tempFormData['attorney_last_name'] = documentData.sendingFirm?.attorney?.lastName;
    tempFormData['attorney'] = buildFullName(documentData.sendingFirm?.attorney);
    tempFormData['attorney_email'] = documentData.sendingFirm?.attorney?.contactInfo?.emailAddress;
    tempFormData['attorney_phone'] = documentData.sendingFirm?.attorney?.contactInfo?.phoneNumber;
    tempFormData['attorney_contact'] =
      documentData.sendingFirm?.primaryContact?.firstName + ' ' + documentData.sendingFirm?.primaryContact?.lastName;
    // tempFormData['attorney_contact_first_name'] = documentData.sendingFirm?.primaryContact?.firstName;
    // tempFormData['attorney_contact_last_name'] = documentData.sendingFirm?.primaryContact?.lastName;
    tempFormData['attorney_contact_email'] = documentData.sendingFirm?.primaryContact?.contactInfo?.emailAddress;
    tempFormData['attorney_contact_phone'] = documentData.sendingFirm?.primaryContact?.contactInfo?.phoneNumber;
    tempFormData['attorney_firm_name'] = documentData.sendingFirm?.firmName;
    tempFormData['attorney_case_number'] = documentData.sendingFirm?.firmCaseNumber;

    tempFormData['collaborators'] = documentData.sendingFirm?.caseManagers.map((collaborator) => {
      return buildFullName(collaborator);
    });

    // Carrier
    if (documentData.recipientCarrier) {
      tempFormData['carrier'] = documentData.recipientCarrier.carrierCommonName;
      if (documentData.recipientCarrier.contactInfo) {
        tempFormData['carrier_phone'] = documentData.recipientCarrier?.contactInfo?.phoneNumber;
        tempFormData['carrier_fax'] = documentData.recipientCarrier?.contactInfo?.faxNumber;
        tempFormData['carrier_email'] = documentData.recipientCarrier?.contactInfo?.emailAddress;
      }
    }

    tempFormData['adjuster_first_name'] = documentData.recipientAdjuster?.firstName;
    tempFormData['adjuster_last_name'] = documentData.recipientAdjuster?.lastName;
    tempFormData['adjuster_phone'] = documentData.recipientAdjuster?.contactInfo?.phoneNumber;

    // Client
    if (documentData.claimInfo?.claimant) {
      tempFormData['client_first_name'] = documentData.claimInfo?.claimant?.firstName;
      tempFormData['client_last_name'] = documentData.claimInfo?.claimant?.lastName;
    }

    // Claim
    tempFormData['claim_number'] = documentData.claimInfo?.claimNumber;
    tempFormData['claim_date_of_loss'] = cleanDate(documentData.claimInfo?.dateOfLoss);
    tempFormData['claim_coverage'] = documentData.claimInfo?.claimCoverage;
    tempFormData['claim_loss_state'] = documentData.claimInfo?.lossState;
    tempFormData['claim_loss_county'] = documentData.claimInfo?.lossCounty;
    tempFormData['named_insured_first_name'] = documentData.claimInfo?.insuredFirstName;
    tempFormData['named_insured_last_name'] = documentData.claimInfo?.insuredLastName;
    tempFormData['demand_amount'] = documentData.demandDetails?.demandOfferToSettleAmount;
    tempFormData['demand_written_date'] = cleanDate(documentData.demandDetails?.demandWrittenDate);
    tempFormData['demand_response_due_date'] = cleanDate(documentData.demandDetails?.demandResponseDueDate);
    tempFormData['policy_limit'] = documentData.claimInfo?.insuredPolicyLimitsAsUnderstoodByFirm;
    tempFormData['is_policy_limit'] = documentData.demandDetails?.policyLimitDemandIndicator || false;

    setFormData(tempFormData);
  };

  const buildFullName = (user) => {
    return user.firstName + ' ' + user.lastName;
  };

  const cleanDate = (dateStr) => {
    const timeRegex = /T\d{2}:\d{2}:\d{2}/g;
    if (dateStr) {
      return dateStr.replace(timeRegex, '');
    }
    return dateStr;
  };

  // UI Handlers
  const showToastMessage = (message, severity = 'success', timeout = 2000) => {
    setToastMessage(message);
    setToastSeverity(severity);
    setToastTimeout(timeout);
    setToastOpen(true);
  };

  const handleToastClose = () => {
    setToastMessage('');
    setToastOpen(false);
  };

  const handleMenuClose = (event) => {
    setAnchorEl(null);
  };

  const handlePreviewDialogClose = () => {
    setPreviewDialogOpen(false);
  };

  const showStatusDialog = (text, isCloseDisabled = false) => {
    setStatusDialogText(text);
    setStatusDialogOpen(true);
    setStatusCloseDisabled(isCloseDisabled);
  };

  const handleStatusDialogClose = () => {
    setStatusDialogOpen(false);
  };

  const handleEmailCarrier = (event) => {
    handleMenuClose(event);
    setCarrierDialogOpen(true);
  };

  const handleCarrierDialogClose = () => {
    setCarrierDialogOpen(false);
  };

  const handleFaxCarrier = (documentId, fax, event) => {
    handleMenuClose(documentId, event);
    setFaxDialogOpen(true);
  };

  const handleCarrierFaxDialogClose = () => {
    setFaxDialogOpen(false);
  };

  const handleArchiveDemand = (event) => {
    handleMenuClose(event);
    setArchiveDialogOpen(true);
  };

  const handleArchiveDialogClose = () => {
    setArchiveDialogOpen(false);
  };

  const handleSendManually = (event) => {
    handleMenuClose(event);
    setManualDialogOpen(true);
  };

  const handleManualDialogClose = () => {
    setManualDialogOpen(false);
  };

  const handleApproveDemandClick = () => {
    setAttorneyDialogOpen(true);
  };

  const handleAttorneyDialogClose = () => {
    setAttorneyDialogOpen(false);
  };

  const handleBackNavClick = () => {
    const state = location.state;
    if (state?.from) {
      navigate(state?.from);
    } else {
      navigate('/inventory');
    }
  };

  const handleUpdateDeliveryChannel = (carrierId, deliveryChannel) => {
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: user.signInUserSession.accessToken.jwtToken,
      },
      body: JSON.stringify(deliveryChannel),
    };

    fetch(rootApiUrl + `/carrier/${carrierId}/deliveryChannel`, settings)
      .then((response) => {
        if (response.status == 200) {
          setToastMessage('Carrier updated successfully');
          setToastOpen(true);
          searchCarriers(user, '');
        } else {
          showStatusDialog('Error updating carrier.');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSendForAiProcessing = () => {
    dispatch(setSendForAIProcessingConfirmation({
      open: true,
      data: {
        documentId,
      }
    }));
  }

  const menuopen = Boolean(anchorEl);
  const adminView = userHasPermission('DemandEdit', userData);

  useEffect(() => {
    let ignoreResponse = false;

    const getBatches = async () => {
      const { batches } = await getDocumentBatches(documentId, user);
      if (!ignoreResponse) {
        dispatch(setBatches(batches));
      }
    };

    if (documentFiles?.length > 0) {
      getBatches();
    }

    return () => {
      ignoreResponse = true;
    };
  }, [documentFiles]);

  useEffect(() => {
    // Generate the summary of provider
    if (medicals?.medicalTreatments) {
      setProviderSummary(buildProviderSummary(medicals.medicalTreatments));
    }
    mapServerDataToFormElements();
  }, [medicals]);

  useEffect(() => {
    return () => {
      dispatch(clearDocumentState());
      dispatch(clearMedicalsState());
    };
  }, []);

  const isEditDocumentsDisabled =
    userHasPermission('DemandCompose', userData) &&
    [AWAITING_MEDICAL_EXTRACTION].includes(documentStatus);

  const isUploadDocumentsVisible =
    isCompositeDemand(documentMetaData.documentType) && userHasPermission('DemandCompose', userData);

  const isDocumentSubmittedOrReceived = documentStatus === 'DocumentSubmitted' || documentStatus === 'DocumentReceived';

  return (
    <Container maxWidth="100">
      <ThemeProvider theme={theme}>
        {documentData && userData ? (
          <>
            <div style={{ position: 'fixed', zIndex: 10, top: 60, left: 5, right: 15, backgroundColor: '#FFFFFF' }}>
              <div className="breadcrumbs-container">
                <Grid container justifyContent={'space-between'}>
                  {/* nav and header */}
                  <Grid item>
                    <Stack spacing={2} direction={'row'} alignItems="center">
                      <Stack spacing={1} direction={'row'} alignItems="center">
                        <IconButton onClick={handleBackNavClick}>
                          <ArrowBack />
                        </IconButton>
                        <Typography variant="h5" color="primary">
                          {documentData.sendingFirm.firmCaseNumber}
                        </Typography>
                      </Stack>
                      {isCompositeDemand(documentMetaData.documentType) ||
                        documentStatus === 'DocumentSubmitted' ||
                        documentStatus === 'DocumentReceived' ? (
                        <Tooltip
                          title={
                            documentStatus !== 'DocumentArchived' && (
                              <DemandWorkflowTooltip documentStatus={isAiProcessingForCreateDocument ? 'AwaitingMedicalExtraction' : documentStatus} />
                            )
                          }
                          placement="right-start"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [-6, -16],
                                  },
                                },
                              ],
                            },
                          }}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                fontWeight: 400,
                                bgcolor: 'transparent',
                              },
                            },
                          }}
                        >
                          <div>
                            <StatusChip
                              type="document"
                              documentStatus={isAiProcessingForCreateDocument ? 'AwaitingMedicalExtraction' : documentStatus}
                              showInfoIcon={documentStatus !== 'DocumentArchived'}
                              showEditOffIcon={false}
                            />
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip title="This demand cannot be edited" placement="bottom-start">
                          <span>
                            <StatusChip
                              type="document"
                              documentStatus={documentStatus}
                              showInfoIcon={!isDocumentSubmittedOrReceived}
                              showEditOffIcon={!isDocumentSubmittedOrReceived}
                            />
                          </span>
                        </Tooltip>
                      )}
                    </Stack>
                  </Grid>

                  {/* actions */}
                  <Grid item>
                    <Stack direction={'row'} spacing={1}>
                      {/* actions menu for md breakpoints and up */}
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                        }}
                        endIcon={<MoreHorizOutlined />}
                        sx={{ display: { xs: 'none', md: 'flex' } }}
                      >
                        Actions
                      </Button>
                      {userHasPermission('DemandRequestApproval', userData) && documentStatus != 'DocumentArchived' && (
                        <>
                          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <EditMenu
                              user={user}
                              userData={userData}
                              showTooltip={hasNotAllowedStatus(documentMetaData)}
                              documentId={documentId}
                              useDemandNotesEnabled={useDemandNotesEnabled}
                              isEditDocumentsDisabled={isEditDocumentsDisabled}
                              editDocumentsToolTipText={DISABLED_EDIT_DOCUMENTS_TOOLTIP_TEXT}
                            />
                          </Box>
                          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            {documentStatus === DOCUMENT_UPLOADED && !isAiProcessingForCreateDocument ? (
                              <Button
                                variant='contained'
                                color='secondary'
                                startIcon={<AutoAwesome />}
                                onClick={() => handleSendForAiProcessing()}
                                disabled = {isPdfRegenLoading}
                              >
                                Send for AI processing
                              </Button>
                            ) : (
                              <Tooltip
                                title={
                                  !isRequestApprovalEnabled(documentStatus, documentMetaData.documentType, userData)
                                    ? ''
                                    : 'Add carrier to contact db to proceed'
                                }
                                placement="top"
                                disableHoverListener={doesCarrierExist(documentData)}
                                arrow
                              >
                                <span>
                                  <Button
                                    disabled={
                                      !isRequestApprovalEnabled(
                                        documentStatus,
                                        documentMetaData.documentType,
                                        userData
                                      ) || !doesCarrierExist(documentData)
                                    }
                                    onClick={(e) => {
                                      handleApproveDemandClick();
                                    }}
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<ApprovalRounded />}
                                  >
                                    {getApprovalCTALabel(documentStatus)}
                                  </Button>
                                </span>
                              </Tooltip>
                            )}
                          </Box>
                        </>
                      )}

                      {/* actions menu for xs and sm breakpoints */}
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                        }}
                        endIcon={<MoreHorizOutlined />}
                        sx={{ display: { xs: 'flex', md: 'none' } }}
                      >
                        Actions
                      </Button>
                      <Menu
                        id="menu-options"
                        keepMounted
                        anchorEl={anchorEl}
                        open={menuopen}
                        disableScrollLock
                        onClose={(e) => {
                          handleMenuClose(e);
                        }}
                      >
                        {/* shows the primary cta as a menu item below md breakpoints */}
                        {userHasPermission('DemandRequestApproval', userData) &&
                          documentStatus != 'DocumentArchived' && (
                            <div>
                              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                <Tooltip
                                  title={
                                    !isRequestApprovalEnabled(documentStatus, documentMetaData.documentType, userData)
                                      ? ''
                                      : 'Add carrier to contact db to proceed'
                                  }
                                  placement="top"
                                  disableHoverListener={doesCarrierExist(documentData)}
                                  arrow
                                >
                                  {documentStatus === DOCUMENT_UPLOADED && !isAiProcessingForCreateDocument ? (
                                    <MenuItem
                                      onClick={() => { handleSendForAiProcessing(); }}
                                      disabled = {isPdfRegenLoading}
                                    >
                                      <ListItemIcon>
                                        <AutoAwesome />
                                      </ListItemIcon>
                                      <ListItemText primary="Send for AI processing" />
                                    </MenuItem>
                                  ) : null}
                                  <MenuItem
                                    disabled={
                                      !isRequestApprovalEnabled(
                                        documentStatus,
                                        documentMetaData.documentType,
                                        userData
                                      ) || !doesCarrierExist(documentData)
                                    }
                                    onClick={(e) => {
                                      handleApproveDemandClick();
                                    }}
                                  >
                                    <ListItemIcon>
                                      <ApprovalRounded />
                                    </ListItemIcon>
                                    <ListItemText primary="Email approval to firm" />
                                  </MenuItem>
                                </Tooltip>
                              </Box>
                              <Divider sx={{ my: 1, display: { xs: 'flex', md: 'none' } }} />
                            </div>
                          )}
                        {userHasPermission('DemandMedicalExtraction', userData) &&
                          (documentStatus === 'DocumentUploaded' || documentStatus === 'RejectedByLawFirm') && !isAiProcessingForCreateDocument && (
                            <MenuItem
                              onClick={(e) => {
                                setAnchorEl(null);
                                dispatch(setMedicalExtractionDialogOpen(true));
                              }}
                            >
                              <ListItemIcon>
                                <MedicalServicesOutlined />
                              </ListItemIcon>
                              <ListItemText primary="Send for medical extraction" />
                            </MenuItem>
                          )}
                        {isUploadMedicalsEnabled(userData, documentStatus) && (
                          <div>
                            <MenuItem
                              onClick={(e) => {
                                setAnchorEl(null);
                                dispatch(
                                  setUploadMedicals({
                                    open: true,
                                    data: {
                                      documentId,
                                      user,
                                    },
                                  })
                                );
                              }}
                            >
                              <ListItemIcon>
                                <UploadFileOutlined />
                              </ListItemIcon>
                              <ListItemText primary="Upload medicals" />
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleMenuClose();
                                dispatch(
                                  setReimportMedicals({
                                    open: true,
                                    data: {
                                      documentId,
                                      user,
                                    },
                                  })
                                );
                              }}
                            >
                              <ListItemIcon>
                                <FileDownloadOutlined />
                              </ListItemIcon>
                              <ListItemText primary="Reimport medicals" />
                            </MenuItem>
                            <Divider />
                          </div>
                        )}
                        {isUploadDocumentsVisible && (
                          <div>
                            <UploadDocumentsMenuItem
                              documentId={documentId}
                              documentStatus={documentStatus}
                              user={user}
                              isDocumentUploading={isDocumentUploading}
                              setIsDocumentUploading={setIsDocumentUploading}
                              handleMenuClose={handleMenuClose}
                              showStatusDialog={showStatusDialog}
                              showToastMessage={showToastMessage}
                              setUploadErrorState={setUploadErrorState}
                              uploadErrorState={uploadErrorState}
                            />
                            <Divider />
                          </div>
                        )}

                        {/* show edit actions in the menu below md breakpoints */}
                        {userHasPermission('DemandEdit', userData) &&
                          userHasPermission('DemandCompose', userData) && [ // Use an array because MUI complains if a fragment is used as a child of Menu
                            canComposeDemand(userData, documentMetaData) && [
                              <MenuItem
                                key="edit-demand-details"
                                sx={{ display: { xs: 'flex', md: 'none' } }}
                                onClick={() => {
                                  setAnchorEl(null);
                                  dispatch(setDemandDetailsEditOpen(true));
                                }}
                              >
                                <ListItemIcon>
                                  <TextSnippetOutlined />
                                </ListItemIcon>
                                <ListItemText primary="Edit demand details" />
                              </MenuItem>,

                              <Tooltip
                                key="edit-documents"
                                title={isEditDocumentsDisabled ? DISABLED_EDIT_DOCUMENTS_TOOLTIP_TEXT : ''}
                                arrow
                                placement="left"
                              >
                                <span>
                                  <MenuItem
                                    sx={{ display: { xs: 'flex', md: 'none' } }}
                                    onClick={() => {
                                      setAnchorEl(null);
                                      dispatch(setEditAllDocumentsOpen(true));
                                    }}
                                    disabled={isEditDocumentsDisabled}
                                  >
                                    <ListItemIcon>
                                      <PictureAsPdfOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary="Edit documents" />
                                  </MenuItem>
                                </span>
                              </Tooltip>,
                              <MenuItem
                                sx={{ display: { xs: 'flex', md: 'none' } }}
                                onClick={() => {
                                  setAnchorEl(null);
                                  dispatch(setEditDocumentNames({ open: true, data: { documentId, user } }));
                                }}
                              >
                                <ListItemIcon>
                                  <PictureAsPdfOutlined />
                                </ListItemIcon>
                                <ListItemText primary="Edit document display names" />
                              </MenuItem>,
                            ],
                            useDemandNotesEnabled && (
                              <MenuItem
                                key="edit-notes"
                                sx={{ display: { xs: 'flex', md: 'none' } }}
                                onClick={() => {
                                  setAnchorEl(null);
                                  dispatch(
                                    setEditDemandNotes({
                                      open: true,
                                      isOpenFromInventory: false,
                                      data: { documentId, user },
                                    })
                                  );
                                }}
                                disabled={!userHasPermission('DemandCompose', userData)}
                              >
                                <ListItemIcon>
                                  <EditNoteSharp />
                                </ListItemIcon>
                                <ListItemText primary="Edit notes" />
                              </MenuItem>
                            ),
                            (canComposeDemand(userData, documentMetaData) || useDemandNotesEnabled) && (
                              <Divider key="edit-action-divider" sx={{ my: 1, display: { xs: 'flex', md: 'none' } }} />
                            ),
                          ]}

                        {userHasPermission('DemandPreview', userData) ? (
                          <div>
                            <MenuItem
                              onClick={(e) => {
                                handlePreviewEmail();
                              }}
                            >
                              <ListItemIcon>
                                <RemoveRedEyeOutlined />
                              </ListItemIcon>
                              <ListItemText primary="Email preview to me" />
                            </MenuItem>
                            <Divider />
                          </div>
                        ) : null}

                        {userHasPermission('PromptBuilder', userData) && (
                          <div>
                            <MenuItem
                              onClick={(e) => {
                                setAnchorEl(null);
                                dispatch(setAiPromptBuilderOpen(true));
                              }}
                            >
                              <ListItemIcon>
                                <DataObjectOutlined />
                              </ListItemIcon>
                              <ListItemText primary="Build prompts" />
                            </MenuItem>
                            <Divider />
                          </div>
                        )}

                        {userHasPermission('DemandSend', userData) && documentStatus != 'DocumentArchived' ? (
                          <div>
                            {/* handleEmailCarrier menu item should only be enabled if the documentFileSize is <24mb, or if it is undefined */}
                            {documentFileSize === undefined || documentFileSize < MAX_EMAIL_FILESIZE_LIMIT ? (
                              <MenuItem
                                onClick={(e) => {
                                  handleEmailCarrier(e);
                                }}
                                disabled={!isDemandReadyForSubmission(documentStatus)}
                              >
                                <ListItemIcon>
                                  <SendOutlined />
                                </ListItemIcon>
                                <ListItemText primary="Email to carrier" />
                              </MenuItem>
                            ) : (
                              <Tooltip title="This file is too large to be sent via email.">
                                <div>
                                  <MenuItem disabled>
                                    <ListItemIcon>
                                      <SendOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary="Email to carrier" />
                                  </MenuItem>
                                </div>
                              </Tooltip>
                            )}
                            <MenuItem
                              onClick={(e) => {
                                handleFaxCarrier(e);
                              }}
                              disabled={!isDemandReadyForSubmission(documentStatus)}
                            >
                              <ListItemIcon>
                                <FaxOutlined />
                              </ListItemIcon>
                              <ListItemText primary="Fax to carrier" />
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                handleSendManually(e);
                              }}
                              disabled={!isDemandReadyForSubmission(documentStatus)}
                            >
                              <ListItemIcon>
                                <CheckBoxRounded />
                              </ListItemIcon>
                              <ListItemText primary="Mark as sent manually" />
                            </MenuItem>
                            {userHasPermission('DemandArchive', userData) && documentStatus != 'DocumentArchived' && (
                              <Divider />
                            )}
                          </div>
                        ) : null}
                        {userHasPermission('DemandArchive', userData) && documentStatus != 'DocumentArchived' ? (
                          <div>
                            <MenuItem
                              onClick={(e) => {
                                handleArchiveDemand(e);
                              }}
                            >
                              <ListItemIcon>
                                <ArchiveOutlined />
                              </ListItemIcon>
                              <ListItemText primary="Archive demand" />
                            </MenuItem>
                          </div>
                        ) : null}
                      </Menu>
                    </Stack>
                  </Grid>
                </Grid>
              </div>
            </div>

            <Stack direction="column" spacing={2} sx={{ marginTop: '135px' }}>
              <CollapseCard title="Demand overview" adminView={adminView} expanded={true}>
                <MedicalsOverview documentData={documentData} adminView={userHasPermission('DemandEdit', userData)} />

                <Divider sx={{ my: 1 }} />
                <MedicalsOverviewPrecedent
                  showCarrierPanel={true}
                  carriersData={carriersData}
                  document={documentData}
                  saveDeliveryChannel={handleUpdateDeliveryChannel}
                />
              </CollapseCard>

              <>
                <MedicalsProviderSummary providerSummary={providerSummary} />

                {medicals?.medicalTreatments && medicals?.medicalTreatments.length > 0 && (
                  <MedicalsTreatmentsChart documentData={documentData} />
                )}
                <MedicalsTreatments
                  viewType="precedent"
                  user={user}
                  adminView={userHasPermission('DemandEdit', userData)}
                  documentId={documentId}
                  medicals={medicals}
                />
              </>
            </Stack>

            <AnimatedDialog open={demandEditOpen}>
              <EditDemand
                carriersData={carriersData}
                saveDeliveryChannel={handleUpdateDeliveryChannel}
                setIsSaving={setIsSaving}
              />
            </AnimatedDialog>

            <SaveAnimationDialog isSaving={isSaving} />

            <StatusDialog
              closeDisabled={statusCloseDisabled}
              handleClose={handleStatusDialogClose}
              dialogOpen={statusDialogOpen}
              dialogText={statusDialogText}
              dialogTitle={statusDialogTitle}
            ></StatusDialog>

            <SendPreviewDialog
              user={user}
              documentId={documentId}
              handler={handleSendComplete}
              handleClose={handlePreviewDialogClose}
              dialogOpen={previewDialogOpen}
            ></SendPreviewDialog>

            <ArchiveDialog
              firmCaseNumber={documentData.sendingFirm.firmCaseNumber}
              handler={archiveDemand}
              handleClose={handleArchiveDialogClose}
              dialogOpen={archiveDialogOpen}
            ></ArchiveDialog>

            <SendManuallyDialog
              handler={sendManually}
              handleClose={handleManualDialogClose}
              dialogOpen={manualDialogOpen}
            ></SendManuallyDialog>

            <CarrierSendFaxDialog
              user={user}
              documentId={documentId}
              carrierName={documentData.recipientCarrier.carrierCommonName}
              deliveryFax={deliveryCarrierFax}
              handler={handleSendComplete}
              handleClose={handleCarrierFaxDialogClose}
              dialogOpen={faxDialogOpen}
            ></CarrierSendFaxDialog>

            {carrierDialogOpen && (
              <CarrierSendDialog
                user={user}
                documentId={documentId}
                carrierName={documentData.recipientCarrier.carrierCommonName}
                deliveryEmail={deliveryCarrierEmail}
                handler={handleSendComplete}
                handleClose={handleCarrierDialogClose}
                dialogOpen={carrierDialogOpen}
                viewType="precedent"
              >
                {' '}
                docData={documentData}{' '}
              </CarrierSendDialog>
            )}

            {attorneyDialogOpen && (
              <AttorneySendDialog
                viewType="precedent"
                user={user}
                userData={userData}
                documentId={documentId}
                deliveryEmail={deliveryAttorneyEmail}
                handler={handleSendComplete}
                handleClose={handleAttorneyDialogClose}
                dialogOpen={attorneyDialogOpen}
              ></AttorneySendDialog>
            )}

            <MedicalExtractionSendDialog
              user={user}
              documentId={documentId}
              carrierCommonName={documentData.recipientCarrier.carrierCommonName}
              claimNumber={documentData.claimInfo.claimNumber}
              handleClose={() => {
                dispatch(setMedicalExtractionDialogOpen(false));
              }}
              dialogOpen={medicalExtractionDialogOpen}
            ></MedicalExtractionSendDialog>
          </>
        ) : (
          <p></p>
        )}
        <>

          <CustomMuiDialogWrapper
            open={aiPromptBuilderOpen}
            fullScreen
            TransitionComponent={Transition}
          >
            <AiPromptBuilder />
          </CustomMuiDialogWrapper>

          <Snackbar
            open={toastOpen}
            autoHideDuration={toastTimeout}
            onClose={handleToastClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Paper elevation={8}>
              <Alert onClose={handleToastClose} severity={toastSeverity} sx={{ width: '100%' }}>
                {toastMessage}
              </Alert>
            </Paper>
          </Snackbar>
        </>
      </ThemeProvider>
    </Container>
  );
};
