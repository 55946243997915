import { Link } from 'react-router-dom';
import React, { useEffect, useState, createRef, useRef } from 'react';
import {
  DataGridPro,
  getGridStringOperators,
  getGridSingleSelectOperators,
  getGridDateOperators,
} from '@mui/x-data-grid-pro';
import CreateNewDemand from './DemandComponents/DemandForms/CreateNewDemand';
import {
  LinearProgress,
  Stack,
  Menu,
  MenuItem,
  Tooltip,
  Button,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  Box,
  Toolbar,
  Container,
  Typography,
  Chip,
} from '@mui/material';
import Title from './CommonComponents/Title';
import { dateComparator } from './comparators';
import {
  getApprovalCTALabel,
  getDocumentStatusDropDownValues,
  deliveryStatusDropDownValues,
  convertUTCDateTimeToLocalDate,
  convertUTCDateTimeToLocalTime,
  mapStatusToLabel,
  userHasPermission,
  mapDeliveryStatusToChipClass,
  mapDeliveryStatusToLabel,
  isRequestApprovalEnabled,
  DEFAULT_PAGE_SIZE,
  MAX_EMAIL_FILESIZE_LIMIT,
  isDemandReadyForSubmission,
  userHasRole,
} from './common';
import {
  MoreHoriz,
  RemoveRedEyeOutlined,
  FaxOutlined,
  SendOutlined,
  ArchiveOutlined,
  EditNoteSharp,
  CheckBoxRounded,
  ApprovalRounded,
  ErrorOutlineRounded,
  MedicalServices,
} from '@mui/icons-material';
import { getConfigValue, archiveApi, sendManuallyApi, rejectDemandApi } from './api';
import { isLawFirmUser, isPrecedentUser, isLawFirmAdmin, LAW_FIRM_APPROVER_ROLE } from './common-roles';
import { PRECEDENT_VIEW_TYPE, LAW_FIRM_VIEW_TYPE } from './common-view-types';
import { AWAITNG_PRECEDENT_SIGNOFF, AWAITING_MEDICAL_EXTRACTION } from './common-demand';
import AttorneySendDialog from './dialogs/AttorneySendDialog';
import ArchiveDialog from './dialogs/ArchiveDialog';
import SendManuallyDialog from './dialogs/SendManuallyDialog';
import CarrierSendFaxDialog from './dialogs/CarrierSendFaxDialog';
import CarrierSendDialog from './dialogs/CarrierSendDialog';
import FirmRejectDialog from './dialogs/FirmRejectDialog';
import FirmApproveDialog from './dialogs/FirmApproveDialog';
import SendPreviewDialog from './dialogs/SendPreviewDialog';
import MedicalExtractionSendDialog from './dialogs/MedicalExtractionSendDialog';
import QuickFilters from './QuickFilters/QuickFiltersComponent';
import StatusDialog from './dialogs/StatusDialog';
import { useSelector, useDispatch } from 'react-redux';
import {
  pageChange,
  pageSizeChange,
  sortChange,
  setLoading,
  setDemandData,
  updateFilterState,
  setFilterToggleValue,
  setRow,
} from './redux/slices/inventorySlice';
import { fetchInventoryDemandData } from './redux/thunks/demandData';
import { isEqual } from 'lodash';
import { useIsMount } from './helpers/reduxHelpers';
import { setMedicalExtractionDialogOpen, setCreateNewDemandOpen, setOverrideReview } from './redux/slices/demandDomSlice';
import { setAttorneyOverrideReview, setAttorneySend, setEditDemandNotes } from './redux/slices/globalDialogueSlice';
import StatusChip from './CommonComponents/Chips/StatusChip';
import CustomMuiDialogWrapper from './CommonComponents/CustomMuiDialogWrapper';
import { Transition } from './Transition';
import { observeEvent } from './observability/observability';
import OverrideReview from './GlobalDialogues/OverrideReview';

const InventoryList = () => {
  const stringOperators = getGridStringOperators().filter((operator) => operator.value !== 'isAnyOf');
  const selectOperators = getGridSingleSelectOperators().filter((operator) => operator.value === 'is');
  const dateOperators = getGridDateOperators().filter((operator) => operator.value === 'is');

  const apiRef = useRef({});
  const gridRef = createRef();
  const dispatch = useDispatch();
  const quickFilterName = 'Inventory';

  const filterState = useSelector((state) => state[quickFilterName]).filterState;
  const { filterModel } = filterState;

  const { medicalExtractionDialogOpen, overrideReview } = useSelector((state) => state.DemandDom);
  const { userData, user } = useSelector((state) => state.User);
  const inventoryState = useSelector((state) => state.Inventory);
  const { createNewDemandOpen } = useSelector(state => state.DemandDom);

  const paginationState = inventoryState.pagination;
  const { loading, inventoryResponseData } = inventoryState;
  const { filterToggleValue } = inventoryState.filterState;
  const isActive = filterToggleValue === 'active';

  const [anchorEls, setAnchorEls] = useState([]);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
  const [statusDialogText, setStatusDialogText] = useState('');
  const [statusDialogTitle, setStatusDialogTitle] = useState('');
  const [carrierDialogOpen, setCarrierDialogOpen] = useState(false);
  const [carrierDemandId, setCarrierDemandId] = useState();
  const [deliveryEmail, setDeliveryEmail] = useState();
  const [carrierName, setCarrierName] = useState();
  const [carrierFaxDialogOpen, setCarrierFaxDialogOpen] = useState(false);
  const [manualDialogOpen, setManualDialogOpen] = useState(false);
  const [deliveryFax, setDeliveryFax] = useState();
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [currentArchiveDemand, setCurrentArchiveDemand] = useState();
  const [currentApprovalDemand, setCurrentApprovalDemand] = useState();
  const [currentMedicalExtractionDemand, setCurrentMedicalExtractionDemand] = useState();
  const [currentApprovalEmail, setCurrentApprovalEmail] = useState();
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [firmApproveDialogOpen, setFirmApproveDialogOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [dateOfSendError, setDateOfSendError] = useState(false);
  const [sendReasonError, setSendReasonError] = useState(false);
  const [sendMethodError, setSendMethodError] = useState(false);
  const [demandCreateCTA, setDemandCreateCTA] = useState(false);
  const [useDemandNotesEnabled, setUseDemandNotesEnabled] = useState(false);
  const [paginationKeys, setPaginationKeys] = useState({});
  const [muiTableKey, setMuiTableKey] = useState(0);
  const [tableFiltersActive, setTableFiltersActive] = useState(false);
  const [visibleRows, setVisibleRows] = useState({});
  const [inactiveInventoryDataFetched, setInactiveInventoryDataFetched] = useState(false);

  const { attorneySend } = useSelector((state) => state.GlobalDialogues);


  const demandData = isActive ? inventoryResponseData.activeDocuments : inventoryResponseData.inActiveDocuments;
  const totalRows = (isActive ? inventoryResponseData.activeTotalRows : inventoryResponseData.inActiveTotalRows) || 0;
  const totalDocuments =
    (isActive ? inventoryResponseData.activeTotalDocuments : inventoryResponseData.inActiveTotalDocuments) || 0;
  const statusCounts = isActive ? inventoryResponseData.activeStatusCounts : inventoryResponseData.inActiveStatusCounts;
  const activeQuickFilter = filterState.quickFilters.find((element) => element.toggleState).id;
  const dataGridData = isActive ? inventoryResponseData.activeDocuments : inventoryResponseData.inActiveDocuments;
  const isMount = useIsMount();
  const viewType = isPrecedentUser(userData) ? PRECEDENT_VIEW_TYPE : LAW_FIRM_VIEW_TYPE;
  const isPrecedentView = viewType === PRECEDENT_VIEW_TYPE;

  const requestBody = {
    //requestBody is conditional on the filterToggleValue
    active_filter: isActive,
    page_size: DEFAULT_PAGE_SIZE,
  };
  const quickFilterStartIndex = viewType !== PRECEDENT_VIEW_TYPE ? 0 : 5;
  const quickFilterEndIndex = viewType !== PRECEDENT_VIEW_TYPE ? 5 : 11;

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: paginationState.pageSize,
  });

  const [sortModel, setSortModel] = useState([
    {
      field: paginationState.sort,
      sort: paginationState.sortDirection,
    },
  ]);

  useEffect(() => {
    if (!isMount) {
      getDemandListApi(paginationModel.page);
    }
  }, [inventoryState.contactData.selectedAttorneys]);

  useEffect(() => {
    if (!inactiveInventoryDataFetched && !isActive) {
      getDemandListApi(paginationModel.page);
      setInactiveInventoryDataFetched(true);
    }
  }, [isActive]);

  window.addEventListener(
    'resize',
    function (event) {
      resizeGrid();
    },
    true
  );

  useEffect(() => {
    resizeGrid();
  }, [gridRef]);

  const getVisibleRows = () => apiRef?.current?.state?.visibleRowsLookup;
  useEffect(() => {
    const visRows = getVisibleRows();
    if (!isEqual(visRows, visibleRows)) {
      setVisibleRows(visRows);
    }
  }, [getVisibleRows()]);

  const handleFilterModelChange = (params) => {
    setTableFiltersActive(true);
    setVisibleRows(getVisibleRows());

    dispatch(updateFilterState({ ...filterState, filterModel: params }));
  };

  useEffect(() => {
    paginationModel.page = paginationState.page;
    paginationModel.pageSize = paginationState.pageSize;
    getDemandListApi(paginationState.page);
  }, []);

  const handleQuickFilterClick = () => {
    setTableFiltersActive(false);
    setMuiTableKey(muiTableKey + 1);
  };

  useEffect(() => {
    if (!isMount && filterModel?.items.every((item) => !!item.value)) {
      getDemandListApi(paginationModel.page);
    }
  }, [filterModel]);

  useEffect(() => {
    if (paginationModel.pageSize !== paginationState.pageSize) {
      dispatch(pageSizeChange({ pageSize: paginationModel.pageSize }));
      getDemandListApi(paginationModel.page);
    } else if (paginationModel.page !== paginationState.page) {
      dispatch(pageChange({ page: paginationModel.page }));
      getDemandListApi(paginationModel.page);
    }
  }, [paginationModel]);

  useEffect(() => {
    if (activeQuickFilter !== filterState.activeQuickFilter) {
      dispatch(updateFilterState({ ...filterState, activeQuickFilter: activeQuickFilter }));
      getDemandListApi(paginationModel.page);
    }
  }, [filterState]);

  useEffect(() => {
    if (sortModel.length > 0) {
      if (sortModel[0].field !== paginationState.sort || sortModel[0].sort !== paginationState.sortDirection) {
        dispatch(sortChange({ sort: sortModel[0].field, sortDirection: sortModel[0].sort }));
        getDemandListApi(paginationModel.page, sortModel[0].field, sortModel[0].sort);
      }
    }
  }, [sortModel]);

  const getDemandListApi = async (page, sortColumn = null, sortDirection = null) => {
    if (page === undefined) {
      page = 0;
    }
    requestBody['page'] = page;
    requestBody['page_size'] = paginationModel.pageSize;
    requestBody['sort_column'] = sortColumn || paginationState.sort;
    requestBody['sort_direction'] = sortDirection || paginationState.sortDirection;
    requestBody['quick_filter'] = activeQuickFilter;
    requestBody['attorney_name_filter'] = inventoryState.contactData.selectedAttorneys;
    requestBody['column_filter_model'] = inventoryState.filterState.filterModel;
    // Since law firm users have 2 statuses rolled into 1 label, add the second status in the filter
    if (
      !isPrecedentView &&
      requestBody.column_filter_model?.items.some((item) => item.value === 'AwaitingMedicalExtraction')
    ) {
      requestBody.column_filter_model = {
        items: [...requestBody.column_filter_model.items, { field: 'status', operator: 'is', value: AWAITNG_PRECEDENT_SIGNOFF }],
        logicOperator: 'or',
      };
    }
    dispatch(fetchInventoryDemandData({ requestBody, token: user.signInUserSession.accessToken.jwtToken }));
    dispatch(setLoading(true));
  };

  const getConfigs = async () => {
    setDemandCreateCTA((await getConfigValue('createDemandEnabled', null, user)) === 1);
    setUseDemandNotesEnabled((await getConfigValue('useDemandNotesEnabled', null, user)) === 1);
  }

  useEffect(() => {
    getConfigs();
  }, []);

  const archiveDemand = async (archiveReason, archiveComments) => {
    setArchiveDialogOpen(false);
    const archiveData = {
      archiveReason: archiveReason,
      archiveComments: archiveComments,
    };
    console.log('Archive: ' + JSON.stringify(archiveData));

    archiveApi(currentArchiveDemand.documentId, archiveData, user).then((response) => {
      if (response.status === 200) {
        showStatusDialog('Success! The demand has been archived.');
        updateDocumentStatus(currentArchiveDemand.documentId, 'DocumentArchived');
        setCurrentArchiveDemand(null);
      } else {
        showStatusDialog('There was an error archiving the demand :-(');
      }
    });
  };

  const sendManually = (sendDate, sendReason, sendMethod) => {
    setManualDialogOpen(false);

    const manualFormData = {
      sendDate: sendDate.format('YYYY-MM-DDTHH:mm:ssZ'),
      sendReason: sendReason,
      sendMethod: sendMethod,
    };

    sendManuallyApi(carrierDemandId, manualFormData, user).then((response) => {
      if (response.status === 200) {
        showStatusDialog('Success! The demand has been updated.');
        getDemandListApi(paginationModel.page);
      } else {
        showStatusDialog('There was an error updating the demand :-(');
      }
    });
  };

  const rejectDemand = (reason, comment) => {
    setRejectDialogOpen(false);

    showStatusDialog('Sending notification...');

    rejectDemandApi(carrierDemandId, { rejectReason: reason, comment: comment }, user).then((response) => {
      if (response.status === 200) {
        showStatusDialog('The notification has been delivered');
        updateDocumentStatus(carrierDemandId, 'AwaitingFirmReview');
      } else {
        showStatusDialog('There was an error delivering the notification.');
      }
    });
  };

  /**
   * Called when the send to carrier has completed it's workflow.
   *
   */
  const sendFirmApprovalRequestComplete = (success) => {
    if (success) {
      updateDocumentStatus(currentApprovalDemand.documentId, 'DocumentApproved');
    }
  };

  /**
   * Called when the send to carrier has completed it's workflow.
   *
   */
  const sendEmailToCarrierComplete = (success) => {
    if (success) {
      updateDocumentStatus(currentApprovalDemand.documentId, 'DocumentApproved');
      getDemandListApi(paginationModel.page);
    }
  };

  /**
   * Called when the send to carrier has completed it's workflow.
   *
   */
  const sendFaxToCarrierComplete = (success) => {
    if (success) {
      updateDocumentStatus(currentApprovalDemand.documentId, 'DocumentApproved');
      getDemandListApi(paginationModel.page);
    }
  };

  /**
   * Called when the send to carrier has completed it's workflow.
   *
   */
  const sendForAttorneyApprovalComplete = (success) => {
    if (success) {
      updateDocumentStatus(inventoryState.row.documentId, 'WaitingFirmApproval');
      getDemandListApi(paginationModel.page);
    }
  };

  /**
   * Called when the preview email send.
   *
   */
  const sendPreviewComplete = (success) => { };

  /**
   * Called when we finished sending document for medical extraction
   *
   */
  const sendForMedicalExtractionComplete = (success) => {
    if (success) {
      updateDocumentStatus(currentMedicalExtractionDemand.documentId, 'AwaitingMedicalExtraction');
    }
  };

  // UI Handlers

  const doesCarrierExist = (row) => {
    return row.carrierCustomerAliasId && document.carrierCustomerAliasId != '';
  };

  const showStatusDialog = (text) => {
    setStatusDialogText(text);
    setStatusDialogOpen(true);
  };

  const handleStatusDialogClose = () => {
    setStatusDialogOpen(false);
  };

  const handleFirmApproveDialogClose = () => {
    setFirmApproveDialogOpen(false);
  };

  const handleFirmApproveDemand = (row, event) => {
    handleMenuClose(row.documentId, event);
    setCurrentApprovalDemand(row);
    setCarrierDemandId(row.documentId);
    setFirmApproveDialogOpen(true);
  };

  const handleRejectDemand = (row, event) => {
    handleMenuClose(row.documentId, event);
    setCarrierDemandId(row.documentId);
    setRejectDialogOpen(true);
  };

  const handleRejectDialogClose = () => {
    setRejectDialogOpen(false);
  };

  const handleSendManually = (id, event) => {
    setFormData({});
    setDateOfSendError(false);
    setSendMethodError(false);
    setSendReasonError(false);

    setCarrierDemandId(id);
    setManualDialogOpen(true);
    handleMenuClose(id, event);
  };

  const handleApproveDemandClick = (event) => {
    handleMenuClose(inventoryState.row.documentId, event);
    setCurrentApprovalEmail(inventoryState.row.attorneyContactEmailAddress);
    dispatch(setAttorneySend({ open: true }));
  };

  const handleArchiveDemand = (row, event) => {
    setFormData({});
    setCurrentArchiveDemand(row);
    setArchiveDialogOpen(true);
    handleMenuClose(row.documentId, event);
  };

  const handleArchiveDialogClose = () => {
    setArchiveDialogOpen(false);
  };

  const handleEditNotesClick = (row, event) => {
    dispatch(
      setEditDemandNotes({
        open: true,
        isOpenFromInventory: true,
        data: { documentId: row.documentId, user, initialNote: row.customerSpecialNotes ?? '' },
      })
    );
    handleMenuClose(row.documentId, event);
  };

  const handleFilterToggleChange = (event, newFilterToggleValue) => {
    if (newFilterToggleValue !== null) {
      paginationKeys.length = 0;
      paginationModel.page = 0;
      setPaginationModel({ ...paginationModel });
      dispatch(pageChange({ paginationKeys: paginationKeys, page: 0, hasNextPage: false }));
      dispatch(setFilterToggleValue(newFilterToggleValue));
    }
  };

  const handleSendManuallyDialogClose = () => {
    setManualDialogOpen(false);
  };

  const handleCarrierFaxDialogClose = () => {
    setCarrierFaxDialogOpen(false);
  };

  const handlePreviewDialogClose = () => {
    setPreviewDialogOpen(false);
  };

  const handleCarrierDialogClose = () => {
    setCarrierDialogOpen(false);
  };

  const showPreviewDialog = (text) => {
    setPreviewDialogOpen(true);
  };

  const handleMenuButtonClick = (id, event) => {
    let els = [...anchorEls];
    els[id] = event.currentTarget;
    setAnchorEls(els);
  };

  const handleMenuClose = (id, event) => {
    anchorEls[id] = null;
    setAnchorEls([...anchorEls]);
  };

  const handlePreviewEmail = (id, event) => {
    setCarrierDemandId(id);
    handleMenuClose(id, event);
    setPreviewDialogOpen(true);
  };

  const handleMedicalExtractionClick = (row, event) => {
    setCarrierDemandId(row.documentId);
    setCurrentMedicalExtractionDemand(row);
    handleMenuClose(row.documentId, event);
    dispatch(setMedicalExtractionDialogOpen(true));
  };

  const handleMedicalExtractionDialogClose = () => {
    dispatch(setMedicalExtractionDialogOpen(false));
  };

  const handleEmailCarrier = (documentId, deliveryEmail, carrierName, event) => {
    setCarrierDemandId(documentId);
    setDeliveryEmail(deliveryEmail);
    setCarrierName(carrierName);
    handleMenuClose(documentId, event);
    setCarrierDialogOpen(true);
  };

  const handleFaxCarrier = (documentId, fax, carrierName, event) => {
    setCarrierDemandId(documentId);
    setDeliveryFax(fax);
    setCarrierName(carrierName);
    handleMenuClose(documentId, event);
    setCarrierFaxDialogOpen(true);
  };

  const updateDocumentStatus = (documentId, newStatus) => {
    const demandDataCopy = [...demandData];
    for (let i = 0; i < demandDataCopy.length; i++) {
      if (demandDataCopy[i].documentId === documentId) {
        const currentStatus = demandDataCopy[i].documentStatus;
        const copy = Object.assign({}, demandDataCopy[i]);
        copy.documentStatus = newStatus;
        demandDataCopy[i] = copy;
        dispatch(setDemandData({ data: demandDataCopy, isActive }));
        if ([newStatus, currentStatus].includes('DocumentArchived')) {
          getDemandListApi(paginationModel.page);
        }
        break;
      }
    }
  };

  const resizeGrid = () => {
    if (gridRef.current !== null) {
      let windowH = window.innerHeight - 200;
      gridRef.current.style.height = windowH + 'px';
    }
  };

  useEffect(() => {
    resizeGrid();
  }, [gridRef]);

  const demand_col = {
    field: 'demand_id',
    headerName: 'Demand',
    width: 150,
    headerAlign: 'left',
    align: 'left',
    filterOperators: stringOperators,
    valueGetter: (params) => {
      return params.row.claimNumber || 'Not on File';
    },
    renderCell: (params) => {
      if (isPrecedentUser(userData)) {
        return (
          <Stack>
            <Link to={'/a/' + params.row.documentId}>
              <Typography variant="tableP1" color="secondary">
                {params.row.claimNumber || params.row.documentId}
              </Typography>
            </Link>
            <Typography variant="tableP2">PIN: {params.row.documentAdminPIN}</Typography>
          </Stack>
        );
      } else if (isLawFirmUser(userData)) {
        return (
          <div style={{ paddingTop: '5px', paddingBottom: '5px' }}>
            <Link to={'/l/' + params.row.documentId}>
              {params.row.claimNumber ? params.row.claimNumber : params.row.documentId}
            </Link>
          </div>
        );
      }
    },
  };

  const matter_col = {
    field: 'matter',
    headerName: 'Matter',
    width: 160,
    headerAlign: 'left',
    filterOperators: stringOperators,
    valueGetter: (params) => {
      return params.row.firmCaseNumber || 'Not on File';
    },
    renderCell: (params) => {
      if (params.row.litifyUrl) {
        return (
          <a target="litify" href={params.row.litifyUrl}>
            <Typography variant="tableP1" color="secondary">
              {params.row.firmCaseNumber || 'Not on File'}
            </Typography>
          </a>
        );
      } else {
        return <Typography variant="tableP1">{params.row.firmCaseNumber || 'Not on File'}</Typography>;
      }
    },
  };

  const lawfirm_col = {
    field: 'lawfirm',
    headerName: 'Law firm',
    width: 150,
    headerAlign: 'left',
    align: 'left',
    filterOperators: stringOperators,
    valueGetter: (params) => {
      if (params.row.firmName) {
        return params.row.firmName;
      }
      return 'Not on File';
    },
    renderCell: (params) => {
      return <Typography variant="tableP1">{params.row.firmName}</Typography>;
    },
  };

  const template_col = {
    field: 'demandTemplateName',
    headerName: 'Template',
    width: 150,
    headerAlign: 'left',
    align: 'left',
  };

  const client_col = {
    field: 'client_name',
    headerName: 'Client',
    width: 150,
    headerAlign: 'left',
    align: 'left',
    filterOperators: stringOperators,
    valueGetter: (params) => {
      if (params.row.claimantFirstName !== null || params.row.claimantLastName !== null) {
        return (params.row.claimantFirstName ?? '') + ' ' + (params.row.claimantLastName ?? '');
      }
      return 'Not on File';
    },
    renderCell: (params) => {
      return (
        <Typography variant="tableP1">
          {params.row.claimantFirstName ?? ''} {params.row.claimantLastName ?? ''}
        </Typography>
      );
    },
  };

  const attorney_col = {
    field: 'attorney_name',
    headerName: 'Attorney',
    width: 150,
    headerAlign: 'left',
    align: 'left',
    filterOperators: stringOperators,
    valueGetter: (params) => {
      if (params.row.attorneyFirstName !== null || params.row.attorneyLastName !== null) {
        return (params.row.attorneyFirstName ?? '') + ' ' + (params.row.attorneyLastName ?? '');
      }

      return 'Not on file';
    },
    renderCell: (params) => {
      return (
        <Typography variant="tableP1">
          {params.row.attorneyFirstName ?? ''} {params.row.attorneyLastName ?? ''}
        </Typography>
      );
    },
  };

  const primary_contact_col = {
    field: 'primary_contact',
    headerName: 'Primary contact',
    width: 150,
    headerAlign: 'left',
    align: 'left',
    filterOperators: stringOperators,
    valueGetter: (params) =>
      (params?.row?.primaryContactFirstName || '') + ' ' + (params.row?.primaryContactLastName || ''),
  };

  const carrier_col = {
    field: 'carrier',
    headerName: 'Carrier',
    width: 160,
    headerAlign: 'left',
    align: 'left',
    filterOperators: stringOperators,
    valueGetter: (params) => {
      return params.row.carrierCommonName;
    },
    renderCell: (params) => {
      return (
        <Stack>
          <Typography variant="tableP1">{params.row.carrierCommonName}</Typography>
          <Typography variant="tableP2">{params.row.claimCoverage || 'Not on file'}</Typography>
        </Stack>
      );
    },
  };

  const date_created_col = {
    field: 'date_created',
    headerName: 'Created',
    type: 'date',
    width: 115,
    sortComparator: dateComparator,
    filterOperators: dateOperators,
    valueGetter: (params) => (params.row.createdTs ? new Date(params.row.createdTs * 1000) : 0),
    renderCell: (params) => {
      return (
        <Stack>
          <Typography variant="tableP1">
            {convertUTCDateTimeToLocalDate(new Date(params.row.createdTs * 1000))}
          </Typography>
          <Typography variant="tableP2">
            {convertUTCDateTimeToLocalTime(new Date(params.row.createdTs * 1000))}
          </Typography>
        </Stack>
      );
    },
  };

  const date_approved_col = {
    field: 'date_approved',
    headerName: 'Approved',
    type: 'date',
    width: 115,
    filterOperators: dateOperators,
    sortComparator: dateComparator,
    valueGetter: (params) => (params.row.dateApproved ? new Date(params.row.dateApproved * 1000) : 0),
    renderCell: (params) => {
      const statusEvent = params.row.dateApproved;
      if (statusEvent) {
        return (
          <Stack>
            <Typography variant="tableP1">{convertUTCDateTimeToLocalDate(new Date(statusEvent * 1000))}</Typography>
            <Typography variant="tableP2">{convertUTCDateTimeToLocalTime(new Date(statusEvent * 1000))}</Typography>
          </Stack>
        );
      } else {
        return '';
      }
    },
  };

  const date_submitted_col = {
    field: 'date_submitted',
    headerName: 'Submitted',
    type: 'date',
    width: 115,
    filterOperators: dateOperators,
    sortComparator: dateComparator,
    valueGetter: (params) => (params.row.dateSubmitted ? new Date(params.row.dateSubmitted * 1000) : 0),
    renderCell: (params) => {
      const statusEvent = params.row.dateSubmitted;
      if (statusEvent) {
        return (
          <Stack>
            <Typography variant="tableP1">{convertUTCDateTimeToLocalDate(new Date(statusEvent * 1000))}</Typography>
            <Typography variant="tableP2">{convertUTCDateTimeToLocalTime(new Date(statusEvent * 1000))}</Typography>
          </Stack>
        );
      } else {
        return '';
      }
    },
  };

  const date_recvd_col = {
    field: 'date_received',
    headerName: 'Received',
    type: 'date',
    width: 115,
    filterOperators: dateOperators,
    sortComparator: dateComparator,
    valueGetter: (params) => (params.row.dateReceived ? new Date(params.row.dateReceived * 1000) : 0),
    renderCell: (params) => {
      const statusEvent = params.row.dateReceived;
      if (statusEvent) {
        return (
          <Stack>
            <Typography variant="tableP1">{convertUTCDateTimeToLocalDate(new Date(statusEvent * 1000))}</Typography>
            <Typography variant="tableP2">{convertUTCDateTimeToLocalTime(new Date(statusEvent * 1000))}</Typography>
          </Stack>
        );
      } else {
        return '';
      }
    },
  };

  const notes_col = {
    field: 'customerSpecialNotes',
    headerName: 'Notes',
    width: 150,
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => {
      return params.row?.customerSpecialNotes ?? '';
    },
    renderCell: (params) => (
      <Tooltip
        title={params.row.customerSpecialNotes}
        placement='left'
        arrow
      >
        <Typography
          variant='tableP1'
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {params.row.customerSpecialNotes}
        </Typography>
      </Tooltip>
    ),
  };

  const document_status_col = {
    field: 'status',
    headerName: viewType === PRECEDENT_VIEW_TYPE ? 'Document status' : 'Status',
    width: 225,
    filterOperators: selectOperators,
    type: 'singleSelect',
    valueOptions: getDocumentStatusDropDownValues(isPrecedentView),
    valueGetter: (params) => {
      return mapStatusToLabel(params.row.documentStatus, isPrecedentView);
    },
    renderCell: (params) => (
      <StatusChip
        type='document'
        documentStatus={params.row.documentStatus}
        showInfoIcon={params.row.documentStatus === AWAITING_MEDICAL_EXTRACTION || (params.row.documentStatus === AWAITNG_PRECEDENT_SIGNOFF && !isPrecedentView)}
        showEditOffIcon={false}
      />
    ),
  };

  const delivery_status_col = {
    field: 'delivery_status',
    headerName: 'Delivery status',
    width: 225,
    filterOperators: selectOperators,
    type: 'singleSelect',
    valueOptions: deliveryStatusDropDownValues,
    valueGetter: (params) => {
      return mapDeliveryStatusToLabel(params.row.deliveryStatus);
    },
    renderCell: (params) => (
      <Chip
        className={mapDeliveryStatusToChipClass(params.row.deliveryStatus)}
        label={mapDeliveryStatusToLabel(params.row.deliveryStatus)}
      />
    ),
  };

  const action_col = {
    field: 'action',
    headerName: '',
    width: 85,
    resizable: false,
    editable: false,
    disableColumnMenu: true,
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      if (
        userHasPermission('DemandSend', userData) ||
        userHasPermission('DemandArchive', userData) ||
        userHasPermission('DemandPreview', userData)
      ) {
        return (
          <div>
            <IconButton
              id={params.row.documentId}
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(e) => {
                handleMenuButtonClick(params.row.documentId, e);
              }}
            >
              <MoreHoriz color="primaryActions" />
            </IconButton>
            <Menu
              id={`${params.row.documentId}-menu`}
              keepMounted
              anchorEl={anchorEls[params.row.documentId]}
              open={Boolean(anchorEls[params.row.documentId])}
              onClose={(e) => {
                handleMenuClose(params.row.documentId, e);
              }}
            >
              {userHasPermission('DemandMedicalExtraction', userData) &&
                (params.row.documentStatus === 'DocumentUploaded' ||
                  params.row.documentStatus === 'RejectedByLawFirm') && (
                  <div>
                    <MenuItem
                      onClick={(e) => {
                        handleMedicalExtractionClick(params.row, e);
                      }}
                    >
                      <ListItemIcon>
                        <MedicalServices />
                      </ListItemIcon>
                      <ListItemText primary="Send for Medical Extraction" />
                    </MenuItem>
                  </div>
                )}

              {userHasPermission('DemandPreview', userData) && (
                <div>
                  <MenuItem
                    onClick={(e) => {
                      handlePreviewEmail(params.row.documentId, e);
                    }}
                  >
                    <ListItemIcon>
                      <RemoveRedEyeOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Email preview to me" />
                  </MenuItem>
                </div>
              )}

              {userHasPermission('DemandSend', userData) && params.row.documentStatus !== 'DocumentArchived' && (
                <div>
                  <Divider />
                  {/* handleEmailCarrier menu item should only be enabled if the params.row.fileSize is < MAX_EMAIL_FILESIZE_LIMIT, or if it is undefined */}
                  {params.row.fileSize === undefined || params.row.fileSize < MAX_EMAIL_FILESIZE_LIMIT ? (
                    <MenuItem
                      onClick={(e) => {
                        handleEmailCarrier(
                          params.row.documentId,
                          params.row.deliveryEmail,
                          params.row.carrierCommonName,
                          e
                        );
                      }}
                      disabled={!isDemandReadyForSubmission(params.row.documentStatus)}
                    >
                      <ListItemIcon>
                        <SendOutlined />
                      </ListItemIcon>
                      <ListItemText primary="Email to carrier" />
                    </MenuItem>
                  ) : (
                    <Tooltip title="This file is too large to be sent via email.">
                      <div>
                        <MenuItem disabled>
                          <ListItemIcon>
                            <SendOutlined />
                          </ListItemIcon>
                          <ListItemText primary="Email to carrier" />
                        </MenuItem>
                      </div>
                    </Tooltip>
                  )}
                  <MenuItem
                    onClick={(e) => {
                      handleFaxCarrier(params.row.documentId, params.row.deliveryFax, params.row.carrierCommonName, e);
                    }}
                    disabled={!isDemandReadyForSubmission(params.row.documentStatus)}
                  >
                    <ListItemIcon>
                      <FaxOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Fax to carrier" />
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      handleSendManually(params.row.documentId, e);
                    }}
                    disabled={!isDemandReadyForSubmission(params.row.documentStatus)}
                  >
                    <ListItemIcon>
                      <CheckBoxRounded />
                    </ListItemIcon>
                    <ListItemText primary="Mark as sent manually" />
                  </MenuItem>
                </div>
              )}
              {userHasPermission('DemandRequestApproval', userData) &&
                params.row.documentStatus !== 'DocumentArchived' &&
                viewType !== LAW_FIRM_VIEW_TYPE && (
                  <div>
                    <Divider />
                    <Tooltip
                      placement="left"
                      title={
                        !isRequestApprovalEnabled(params.row.documentStatus, params.row.documentType, userData)
                          ? ''
                          : 'Add carrier to contact db to proceed'
                      }
                      disableHoverListener={doesCarrierExist(params.row)}
                      arrow
                    >
                      <div>
                        <MenuItem
                          disabled={
                            !isRequestApprovalEnabled(params.row.documentStatus, params.row.documentType, userData) ||
                            !doesCarrierExist(params.row)
                          }
                          onClick={(e) => {
                            handleApproveDemandClick(params.row, params.row.deliveryAttorneyEmail, e);
                          }}
                        >
                          <ListItemIcon>
                            <ApprovalRounded />
                          </ListItemIcon>
                          <ListItemText primary={getApprovalCTALabel(params.row.documentStatus)} />
                        </MenuItem>
                      </div>
                    </Tooltip>
                  </div>
                )}
              {userHasPermission('DemandApproval', userData) && params.row.documentStatus !== 'DocumentArchived' && (
                <div>
                  {params.row.documentStatus !== 'AwaitingFirmReview' && (
                    <>
                      <MenuItem
                        disabled={params.row.documentStatus !== 'WaitingFirmApproval'}
                        onClick={(e) => {
                          handleFirmApproveDemand(params.row, e);
                        }}
                      >
                        <ListItemIcon>
                          <ApprovalRounded />
                        </ListItemIcon>
                        <ListItemText primary="Approve and send to carrier" />
                      </MenuItem>
                      <MenuItem
                        disabled={params.row.documentStatus !== 'WaitingFirmApproval'}
                        onClick={(e) => {
                          handleRejectDemand(params.row, e);
                        }}
                      >
                        <ListItemIcon>
                          <ErrorOutlineRounded />
                        </ListItemIcon>
                        <ListItemText primary="Request additional review" />
                      </MenuItem>
                    </>
                  )}
                  {params.row.documentStatus === 'AwaitingFirmReview' && (
                    <MenuItem
                      onClick={(e) => {
                        dispatch(
                          setRow({
                            ...params.row,
                          })
                        );
                        handleMenuClose(params.row.documentId, e);
                        if (userHasRole(LAW_FIRM_APPROVER_ROLE, userData)) {
                          dispatch(
                            setAttorneyOverrideReview({
                              open: true,
                            })
                          );
                        } else {
                          handleApproveDemandClick(inventoryState.row.documentId, inventoryState.row.attorneyContactEmailAddress, e);
                        }

                      }}
                    >
                      <ListItemIcon>
                        <ApprovalRounded />
                      </ListItemIcon>
                      <ListItemText primary="Send for approval" />
                    </MenuItem>
                  )}
                </div>
              )}
              {useDemandNotesEnabled && (
                <div>
                  <MenuItem
                    disabled={!userHasPermission('DemandCompose', userData)}
                    onClick={(e) => {
                      handleEditNotesClick(params.row, e);
                    }}
                  >
                    <ListItemIcon>
                      <EditNoteSharp />
                    </ListItemIcon>
                    <ListItemText primary="Edit notes" />
                  </MenuItem>
                </div>
              )}
              {userHasPermission('DemandArchive', userData) && params.row.documentStatus !== 'DocumentArchived' && (
                <div>
                  <Divider />
                  <MenuItem
                    onClick={(e) => {
                      handleArchiveDemand(params.row, e);
                    }}
                  >
                    <ListItemIcon>
                      <ArchiveOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Archive demand" />
                  </MenuItem>
                </div>
              )}
            </Menu>
          </div>
        );
      } else return null;
    },
  };

  const columns =
    viewType === PRECEDENT_VIEW_TYPE
      ? [
        demand_col,
        template_col,
        matter_col,
        lawfirm_col,
        carrier_col,
        client_col,
        attorney_col,
        date_created_col,
        date_approved_col,
        date_submitted_col,
        date_recvd_col,
        ...(useDemandNotesEnabled ? [notes_col] : []),
        document_status_col,
        delivery_status_col,
        action_col,
      ]
      : [
        demand_col,
        template_col,
        matter_col,
        carrier_col,
        client_col,
        attorney_col,
        primary_contact_col,
        date_created_col,
        date_approved_col,
        date_submitted_col,
        ...(useDemandNotesEnabled ? [notes_col] : []),
        document_status_col,
        action_col,
      ];

  return (
    <div>
      <Container maxWidth="">
        <Stack direction="column" spacing={2}>
          <Toolbar />
          <Box>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              <Title>Demands</Title>

              <Stack direction={'row'} spacing={2} justifyContent={'flex-end'}>
                {userHasPermission('DemandCompose', userData) && demandCreateCTA && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={(e) => {
                      observeEvent("composeDemandClick");
                      dispatch(setCreateNewDemandOpen(true));
                    }}
                  >
                    Compose demand
                  </Button>
                )}
              </Stack>
            </Stack>

            <Stack direction={'row'} justifyContent={'space-between'} spacing={2} sx={{ mt: 1.5 }}>
              <Stack direction={'row'} justifyContent={'flex-start'}>
                <Grid container sx={{ display: 'flex' }}>
                  {filterToggleValue === 'active' && (
                    <QuickFilters
                      handleClick={handleQuickFilterClick}
                      filterName={quickFilterName}
                      loading={loading}
                      data={demandData}
                      statusCounts={statusCounts}
                      rowsInGrid={totalRows}
                      totalDocuments={totalDocuments}
                      isActive={isActive}
                      startIndex={quickFilterStartIndex}
                      endIndex={quickFilterEndIndex}
                      visibleRows={visibleRows}
                      tableFiltersActive={tableFiltersActive}
                      showCustomFilter={isLawFirmAdmin(userData)}
                    />
                  )}
                </Grid>
              </Stack>

              <Stack direction={'row'} spacing={2} sx={{ height: 38.75 }} justifyContent={'flex-end'}>
                <ToggleButtonGroup value={filterToggleValue} exclusive onChange={handleFilterToggleChange}>
                  <ToggleButton value="active">Active</ToggleButton>
                  {userHasPermission('ArchivedDemandPreview', userData) && (
                    <ToggleButton value="inactive">Inactive</ToggleButton>
                  )}
                </ToggleButtonGroup>
              </Stack>
            </Stack>

            <DataGridPro
              key={muiTableKey}
              apiRef={apiRef}
              ref={gridRef}
              pagination
              getRowId={(row) => row.documentId}
              columns={columns}
              rows={dataGridData}
              rowCount={totalRows}
              initialState={{
                sorting: {
                  sortModel: [{ field: paginationState.sort, sort: paginationState.sortDirection }],
                },
                pinnedColumns: { left: ['demand_id'] },
                pagination: { paginationModel: { pageSize: paginationState.pageSize } },
              }}
              getRowHeight={() => 'auto'}
              slots={{
                loadingOverlay: LinearProgress,
              }}
              filterDebounceMs={500}
              disableRowSelectionOnClick={true}
              disableColumnSelector={false}
              loading={loading}
              paginationModel={paginationModel}
              sortModel={sortModel}
              pageSizeOptions={[25, 50, 100]}
              paginationMode="server"
              onPaginationModelChange={setPaginationModel}
              sortingMode="server"
              onSortModelChange={setSortModel}
              filterModel={filterModel}
              onFilterModelChange={handleFilterModelChange}
              filterMode="server"
              sx={{
                mt: 1,
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'doc-row-even' : 'doc-row-odd'
              }
            ></DataGridPro>
          </Box>
        </Stack>
      </Container>

      <StatusDialog
        closeDisabled={statusCloseDisabled}
        handleClose={handleStatusDialogClose}
        dialogOpen={statusDialogOpen}
        dialogText={statusDialogText}
        dialogTitle={statusDialogTitle}
      ></StatusDialog>

      <SendPreviewDialog
        user={user}
        documentId={carrierDemandId}
        handler={sendPreviewComplete}
        handleClose={handlePreviewDialogClose}
        dialogOpen={previewDialogOpen}
      ></SendPreviewDialog>

      {currentMedicalExtractionDemand && (
        <MedicalExtractionSendDialog
          user={user}
          documentId={carrierDemandId}
          carrierCommonName={currentMedicalExtractionDemand.carrierCommonName}
          claimNumber={currentMedicalExtractionDemand.claimNumber}
          handler={sendForMedicalExtractionComplete}
          handleClose={handleMedicalExtractionDialogClose}
          dialogOpen={medicalExtractionDialogOpen}
        ></MedicalExtractionSendDialog>
      )}

      {carrierDialogOpen && (
        <CarrierSendDialog
          user={user}
          documentId={carrierDemandId}
          carrierName={carrierName}
          deliveryEmail={deliveryEmail}
          handler={sendEmailToCarrierComplete}
          handleClose={handleCarrierDialogClose}
          dialogOpen={carrierDialogOpen}
          viewType={viewType}
          source="inventory"
        ></CarrierSendDialog>
      )}

      {carrierFaxDialogOpen && (
        <CarrierSendFaxDialog
          user={user}
          documentId={carrierDemandId}
          carrierName={carrierName}
          deliveryFax={deliveryFax}
          handler={sendFaxToCarrierComplete}
          handleClose={handleCarrierFaxDialogClose}
          dialogOpen={carrierFaxDialogOpen}
        ></CarrierSendFaxDialog>
      )}

      <SendManuallyDialog
        handler={sendManually}
        handleClose={handleSendManuallyDialogClose}
        dialogOpen={manualDialogOpen}
      ></SendManuallyDialog>

      {currentArchiveDemand ? (
        <ArchiveDialog
          firmCaseNumber={currentArchiveDemand.firmCaseNumber}
          handler={archiveDemand}
          handleClose={handleArchiveDialogClose}
          dialogOpen={archiveDialogOpen}
        ></ArchiveDialog>
      ) : null}

      {inventoryState.row && attorneySend.open ? (
        <AttorneySendDialog
          viewType={viewType}
          user={user}
          userData={userData}
          documentId={inventoryState.row.documentId}
          deliveryEmail={`${inventoryState.row.attorneyFirstName} ${inventoryState.row.attorneyLastName}`}
          handler={sendForAttorneyApprovalComplete}
          source="inventory"
        ></AttorneySendDialog>
      ) : null}

      <FirmRejectDialog
        handler={rejectDemand}
        handleClose={handleRejectDialogClose}
        dialogOpen={rejectDialogOpen}
      ></FirmRejectDialog>

      {currentApprovalDemand ? (
        <FirmApproveDialog
          user={user}
          handler={sendFirmApprovalRequestComplete}
          documentId={currentApprovalDemand.documentId}
          deliveryEmail={currentApprovalDemand.deliveryEmail}
          deliveryFax={currentApprovalDemand.deliveryFax}
          carrierEmailAddress={currentApprovalDemand.carrierEmailAddress}
          carrierFaxNumber={currentApprovalDemand.carrierFaxNumber}
          handleClose={handleFirmApproveDialogClose}
          viewType={viewType}
          dialogOpen={firmApproveDialogOpen}
          source="inventory"
        ></FirmApproveDialog>
      ) : null}

      {userHasPermission('DemandCompose', userData) && (
        <CustomMuiDialogWrapper
          open={createNewDemandOpen}
          fullWidth={true}
          fullScreen={true}
          TransitionComponent={Transition}
        >

          <CreateNewDemand
            useDemandNotesEnabled={useDemandNotesEnabled}>
          </CreateNewDemand>

        </CustomMuiDialogWrapper>
      )}

      <OverrideReview
        carrierCommonName={inventoryState.row.carrierCommonName}
      />

    </div>
  );
};

export default InventoryList;
